/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, keywords, title, robots }) {
	let site;

	try {
		site = useStaticQuery(
			graphql`
				query Sites {
					site {
						siteMetadata {
							title
							description
							author
							siteUrl
						}
					}
					ogimage: file(relativePath: { eq: "BLOG-HERO-EDUCATION.jpg" }) {
						publicURL
					}
				}
			`
		);
	} catch (e) {
		site = {
			site: {
				siteMetadata: {
					title: "Synetech",
					description: "Synetech",
					author: "Synetech",
					siteUrl: "https://synetech.cz/"
				}
			},
			ogimage: {
				publicURL: ""
			}
		};
		console.error(e);
	}

	const metaDescription = description || site.site.siteMetadata.description;

	let metas = [
		{
			name: `description`,
			content: metaDescription
		},
		{
			property: `og:title`,
			content: title
		},
		{
			property: `og:description`,
			content: metaDescription
		},
		{
			property: `og:type`,
			content: `website`
		},
		{
			property: `og:image`,
			content: `${site.site.siteMetadata.siteUrl}${site.ogimage.publicURL}`
		},
		{
			name: `twitter:card`,
			content: `summary`
		},
		{
			name: `twitter:creator`,
			content: site.site.siteMetadata.author
		},
		{
			name: `twitter:title`,
			content: title
		},
		{
			name: `twitter:description`,
			content: metaDescription
		},
		meta
	];

	if (keywords.length > 0)
		metas = metas.concat({ name: `keywords`, content: keywords.join(`, `) });
	if (robots) metas = metas.concat({ name: `robots`, content: robots });

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={title}
			titleTemplate={`%s | ${site.site.siteMetadata.title}`}
			meta={metas}
		/>
	);
}

SEO.defaultProps = {
	robots: null,
	lang: `cs`,
	meta: [],
	keywords: [],
	description: ``
};

SEO.propTypes = {
	robots: PropTypes.string,
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string
};

export default SEO;
